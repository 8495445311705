.contentWrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: #17262f;
    width: 100%;
}
.svgImageContainer{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 720px;
}
.svgBaner{
    width: 100%;
    max-width: 1100px;
    font-family: disRegular;
    
}

.svgImage{
    width: 100%;
    padding-top: 56.25%; /* Maintain a 16:9 aspect ratio (9 / 16 * 100%) */
}



