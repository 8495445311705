.sectionWrapper{
    background-color:  var(--primary-bacground-color);
    box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
    font-family: disRegular;
    border-radius: 16px;
    height: 100%;
    display: grid;
    padding: 0px;

    grid-auto-rows:1fr  60.5px 62px;
    -ms-overflow-style: none;
    grid-template-areas:
        "body"
        "footer"
        "header";
}

.header{
    padding: 0;
    grid-area:header;
    color: var(--primary-foreground-color);
    margin: 10px;
    text-align: center;
}
.category{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding:4px;
    
}
.category h2{
    margin:4px 4px 4px 16px;
    font-size: var(--headline-large-font-size); 
    padding:0%;
    font-weight: 600;
}
.menu{
    width: 40px;
    margin: 5px;
}
.categoryTitle{
    flex: 1 1;
}

.body {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: var(--primary-foreground-color);
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 0px;
    flex-direction: column;
    align-self: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    margin: 0;
    color:var(--primary-bacground-color);

}
.body::-webkit-scrollbar {
    display: none;
}
.controlBox{
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: row;
}
.resetBtnBox{
    text-align: left;
    margin-left: 34px;
    flex: 1 1;
    
}
.icon {
    width: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 28px;
    transition: transform 0.4s ease; /* Set transition for the transform property */
    margin-top: 6px;
    padding: 3px;
}

.icon:hover {
    transform: scale(1.2); /* Use transform to scale the icon on hover */
}

.shake{
    animation: shake 0.5s;
    animation-iteration-count: 3s;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.itemsWrapper{
    margin: 0px 20px;
    margin-bottom: 30px;
}

.footer{
    border-top: 1px solid var(--price-bar-top-border-background);
    grid-area: footer;
    background-color: var(--price-bar-background);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 18px;
    color:var(--primary-bacground-color);
    z-index: 3;
}
.resetBtn{
    background-color: white; /* Green background */
    border: 2px solid var(--primary-bacground-color); /* Remove borders */
    color: var(--primary-bacground-color); /* White text */
    padding: 4px 12px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the link block-level */
    font-size: 16px;
    font-weight: 600; /* Increase font size */
    margin: 13px 2px; /* Add some margin */
    cursor: pointer; /* Add a pointer cursor on hover */
    border-radius: 25px; /* Rounded corners */
    transition: all 0.3s; /* Smooth transition */
}
.resetBtn:hover{
    transform: scale(1.1);
    background-color: var( --button-hover-background);
  }
  .resetBtn:active {
    background-color: rgb(184, 34, 34);/* Green background */
    border: 2px solid var(--primary-bacground-color); /* Remove borders */
    color: rgb(246, 245, 245); /* White text */
    transform: scale(0.95);
  }
 
@media screen and (max-width: 480px) {
    .itemsWrapper{
        margin: 20px 0;
    }
    .sectionWrapper{
        grid-auto-rows:  1fr  59.5px 62px;  
    }
    .category{
        margin-top: 20px;
        margin-left: 0px;
        margin-bottom: 10px;
        padding:4px;
        
    }
    .resetBtnBox{
        text-align: left;
        margin-left: 14px;
    }
  }