.recipientBox{
    border-radius: 16px;
    height: 200px;
    box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
    margin: 4px 0;
    max-width: 460px;
    min-width: 360px;
    background-color:#4581ad;
}
.itemsWrapper{
    border-radius: 16px;
    margin: 10px;
}
.headerBox{
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    align-items: center;
}
.title{
    margin-left: 10px;
    width: 100%;
    text-align: left;
}
.delete{
    display: flex;
    border-radius: 40px;
    width: 46px;
    font-size: 22px;
    height: 36px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.delete:hover{
    background-color: var(--primary-foreground-color);
}
.inputs{
   display: flex;
   flex-direction: column; 
}
.inputBox{
    display: flex;
    flex-direction: row;
    margin: 2px 10px;
    font-size: 16px;
    cursor: pointer;
    padding: 2px;
}
.label{
    margin-top: 6px;
    width: 80px;
    text-align: left;
}
.input{
    font-size: 16px;
}
.checkboxBox{
    margin: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.checkbox{
    font-size: 22px;
    margin: 2px;
}
.term{
    font-size: 14px;
    margin: 6px;
    text-align: left;
}
.optionsBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10%;
}
.option{
    display: flex;
    height: 110px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
}
.dragIcon{
    margin-top: -40px;
    font-size: 42px;
}
.deleteBox:hover{
    display: flex;
    justify-content: center;
    width: 70px;
    padding: 20px;
    box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
    border-radius: 16px;
    background-color: rgb(184, 63, 63);
    color: rgb(255, 255, 255);
   
}
.deleteBox{
    display: flex;
    justify-content: center;
    width: 70px;
    padding: 20px;
    box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    color: rgb(184, 63, 63);
    border: 1px solid rgb(184, 63, 63);
}
.valid{
    font-size: 10px;
    margin: 5px 0 0 10px;
    color:rgb(25, 137, 25);
}
.inValid{
    font-size: 10px;
    margin: 5px 0 0 10px;
    color:rgb(228, 81, 110);
}
@media screen and (max-width: 480px) {
    .recipientBox{
        background-color: white;
        border-radius: 16px;
        box-shadow: rgba(1, 0, 0, 0.858) 0px 1px 2px 0px;
        margin: 4px 0;
        max-width: 460px;
    }
}