.sectionWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 0;
}
.cardWrapper{
    display: flex;
    margin: 4px;
    gap:4px;
    flex: 1;
  
}
.banerWrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 6px;
    flex-grow: 1;
    
    color: #17262f;
}
.card{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 6px;
    box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
    background-color: hwb(0 93% 10.8%);
    flex-grow: 1;
    color: #17262f;
    min-width: 320px;
}
.contentWrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin: 4%;
    max-width: 840px;
}

.brightFont{
    color:rgb(215, 214, 214);
}