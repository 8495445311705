.equipmentList{
    padding: 20px 40px;
    letter-spacing:.2px;
    font-family: disRegular;;
    font-size: var(--label-font-size);
}

.noQty{
    font-size: var(--item-List-Small-label-font-size); 
}
.dotSpan{
    margin-left: 26px;
}
@media screen and (max-width: 480px) {
    .equipmentList{
        margin: 8px 30px;
        padding: 0px;
    }
    
  }