.contentWrapper{
    margin: 2% 20px 4% 20px;
}

p{
    margin: 0;
}
.content{ 
    letter-spacing: .1px;
    font-family: disRegular;
    font-size: clamp(1rem, 3vw, 1.4rem); /* Set a max font size of 3rem and a min of 2rem */
    text-align: center;
    width: 100%;
}
.brightFont{
    color:#e4e4e6
}

@media screen and (max-width: 480px) {
    .content{
        letter-spacing: .1px;
        font-family: disRegular;
        font-size: 18px;
        width: 100%;
    }
   
  }
  