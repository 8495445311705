@import '../calcAppGlobal.module.css';

.sectionWrapper{
  background-color:  var(--primary-bacground-color);
  box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
  font-family: disRegular;
  border-radius: 16px;
  height: 100%;
  display: grid;
  grid-auto-rows:  1fr 62px;
  -ms-overflow-style: none;
  grid-template-areas:
      "body"
      "header";
}
.pageWrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  
 }
 .pagetitle{
  width: 100%;
  text-align: center;
  font-size: 22px;
  color:var(--primary-foreground-color);
  margin: 15px 0 0 20px;
 }
 .infoIcon {
    width: 44.5px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 28px;
    transition: transform 0.4s ease; /* Set transition for the transform property */
    margin-top: 6px;
    padding: 2px;
    color: var(--primary-foreground-color);
 }
 
 .infoIcon:hover {
     transform: scale(1.2); /* Use transform to scale the icon on hover */
 }
.header{
  padding: 0;
  grid-area:header;
  margin: 10px;
  text-align: center;
}
.body {
  grid-area: body;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-self: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  flex-wrap: nowrap;
}
.body::-webkit-scrollbar {
  display: none;
}

.gridContainer{
  overflow: hidden;
  display: grid;
  grid-template-columns:1fr 1fr;
  grid-template-rows:1fr 1fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
      "termsBox priceBox"
      "settingsBox settingsBox ";
}
.termsBox{
  grid-area: termsBox;
}
.termsWrapper{
    margin: 0px;
}
.titleBox{
  display: flex;
  flex-direction: row;
  color:var(--primary-foreground-color);
  font-weight: 400;
  font-size: var(--headline-font-size);
  border-bottom: 2px solid var(--primary-bright-foreground-color);
  text-align: left;
  margin: 6px 0;
  align-items:  center;
}
.icon{
  margin: 3px;
  font-size: var(--headline-icon-font-size);
}
.title{
  font-size: var(--headline-font-size);
}
.priceBox{
  grid-area: priceBox;
  display: flex;
  align-items: center;
  width: 100%;
}

.subBox{
  margin: 0px;
  display: flex;
  flex-direction: row;
}
.priceLabel{
  flex: 1;
  font-size: var(--label-font-size);
  color: var(--primary-bright-foreground-color);
  text-align: right;
}

.price{
  color: var(--primary-foreground-color);
  flex: 1;
  font-size: var(--label-font-size);
  font-weight: 400;
  font-family: 'Roboto Mono', monospace;
  text-align: right;
}

.sliderLabel{
  color:var(--primary-bacground-color);
  font-size: var(--headline-font-size);
  font-weight: 600;
}
  .priceWrapper{
    margin: 10px;
    width: 100%;
  }
 
  .unitBox{
    margin: 0 20px;
  }
  
  .typeBox{
  flex: 1 1;
  text-align: left;
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .typeLabel{
    margin: 0px 0 0 40px;
    color: var(--primary-bacground-color);
    font-weight: 600;
    font-size: var(--headline-font-size);
  }
  .typeWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px;
    border-radius: 16px;
  }
  .type{
    font-weight: 600;
    color:var(--primary-bacground-color);
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

.typeCheckBox{
  margin-right: 2px;
  font-size:  var(--headline-large-font-size);
}
.checkBoxLabel{ 
  font-size:  var(--label-font-size);
}

.settingsBox{
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  grid-area: settingsBox;
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: var(--primary-foreground-color);
  margin: 0px 10px 0 10px;
  padding: 4px;
}
  .settingsUnitBox{
    flex: 1 1;
    color:var(--primary-bacground-color);
    padding: 0px 20px;
    margin: 0px 20px;
  }
  .upfrontTitleInputBox{
    margin: 0px 0;
    display: inline-block;
  }
  .upfrontInput{
    border: 1px solid var(--primary-foreground-50-color);
    font-size: var(--headline-font-size);
    margin-left: 5px;
    text-align: right;
    font-family: 'Roboto Mono', monospace;
    width: 90px;
    background-color: var(--primary-foreground-color);
    color:var(--primary-bacground-color);
    border-radius: 6px;
  }
  .sliderTitle{
    text-align: left;
    margin:0;
    color: var(--primary-bacground-color);
  }
  .sliderTitle p{
    padding: 0;
    margin: 0;
  }

.homeowner {
  --thumb-background-color:var(--primary-foreground-color);
  --thumb-border-color: var(--primary-bacground-color);
  --mark-label-color:var(--primary-bacground-color);
  --track-color: var(--primary-bacground-color);
  --rail-background: var(--primary-bacground-color);
}

.notHomeowner {
  --thumb-background-color: var(--primary-bright-foreground-color); 
  --thumb-border-color: var(--primary-bright-foreground-color); 
  --mark-label-color: var(--primary-bright-foreground-color); 
  --track-color: var(--primary-bright-foreground-color); 
  --rail-background: var(--primary-bright-foreground-color); 
}

.instructionsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0);
  transform-origin: calc(100% - 20px) calc(0% + 20px); /* Adjusted to account for the 20px offset */
  transition: transform 0.4s ease, opacity 0.4s ease;
  pointer-events: none;
  z-index: 1000; /* Make sure it overlays the page */
  border-radius: 5px;
}

.instructionsOverlay.show {
  opacity: 1;
  transform: scale(1); /* Grow to full size */
  pointer-events: auto;
  
}

.instructionsModal {
  padding: 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  position: relative;
  color: var(--primary-bacground-color);
  background: white;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  color: var(--primary-background-color);
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 0.4s ease; /* Set transition for the transform property */
}

.closeBtn:hover {
  transform: scale(1.2); /* Use transform to scale the icon on hover */
}




.modalTitle {
  margin-top: 0;
}
.modalSubTitle{
  padding: 10px 0;
}
.modalList{
  margin: 0px;
  padding: 14px;
}
.modalLine{
  padding: 4px;
}
.sectionWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
  @media screen and (max-width: 480px) {
    .sectionWrapper{
      grid-auto-rows:  1fr  62px;   
  }
    .termsBox{
      display: none;
    } 
    .titleBox{
      margin: 0;
    }
    .gridContainer{ 
        height: 100%;
        display: grid;
        grid-template-rows:1fr 1fr;
        grid-template-columns:1fr 1fr;
        grid-template-areas:
            "priceBox priceBox"
            "settingsBox settingsBox ";
      }
    .sliderTitle{
      text-align: left;
      margin: 0 6px;
    }
    
    .unitBox{
      margin: 0px 0px ;
    }
    .settingsBox{
      margin: 10px 10px 0 10px;
    }
    .settingsUnitBox{
      margin: 10px;
    }
    .mobileTerms{
      grid-area: mobileTerms;
      text-align: center;
      font-size: 10px;
      margin: 10px;
    
    }
    .upfrontInput{
      width: 80px;
      font-size: var(--headline-font-size);
    }
    .typeCheckBox{
      font-weight: 600;
      margin-right: 2px;
      font-size:   16px
    }
    .checkBoxLabel{ 
      font-size:  14px;
    }
    .typeLabel{
      margin: 0px 0 0 30px;
      
    }
    .infoIcon {
      margin-top: 5px;
      width: 50px;
      
  }
  .pagetitle{
        margin: 10px 0 0 40px;
    }
    .instructionsModal {
      padding: 20px;
    }
  }