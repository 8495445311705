

.appBackground{
    display: flex;
    position: fixed;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(208, 208, 208);
    z-index: 2;
  }
  .card{
    display: flex;
    width: 100%;
    max-width: 820px;
    max-height: 940px;
    flex-direction: column;
    margin: 3px;
    background-color: #ffffff;
    border-radius: 16px;
    transition: all 3.3s cubic-bezier(.25,.8,.25,1);
    justify-content: space-between;
  }
  
  
  .sectionWrapper{
      background-color:  var(--primary-bacground-color);
      box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
      font-family: disRegular;
      border-radius: 16px;
      height: 100%;
      display: grid;
      grid-auto-rows:  1fr 62px;
      -ms-overflow-style: none;
      grid-template-areas:
          "body"
          "header";
          
    }
    .sectionWrapperFull{transition: background-color 0.3s; 
      background-color:  var(--primary-bacground-color);
      box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
      font-family: disRegular;
      border-radius: 16px;
      height: 100%;
      display: grid;
      grid-auto-rows:  1fr;
      -ms-overflow-style: none;
      grid-template-areas:
          "body";
          
    }
    .header{
      padding: 0;
      grid-area:header;
      margin: 10px;
      text-align: center;
    }
    .body {
      background-color: white;
      overflow-x: hidden;
      font-size: var(--label-font-size);
      display: flex;
      width:100%;
      height: 100%;
      flex-direction: column;
      align-self: flex-start;
      align-items: center;
      border-radius: 16px;
      text-align: center;
      margin: 0px auto;
      -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }
  
    .body::-webkit-scrollbar {
      display: none;
    }
  .button:hover {
      background-color: var(--primary-bacground-color);; /* Darker green on hover */
      color:white;
  }
  
  .procceedBox{
    height: 100%;
    display: grid;
    align-self: center;
    align-items: center;
    grid-auto-rows:  1fr 1fr;
    -ms-overflow-style: none;
    grid-template-areas:
        "messageBox"
        "buttonBox";
  }
  .messageBox{
    color:var(--primary-bacground-color);
    padding: 20px;
    grid-area:messageBox;
    text-align: center;
    max-width: 400px;
  }
  .spinnerContainer{
      margin: 20px;
  }
  .buttonBox{
    padding: 0;
    grid-area:buttonBox;
    margin: 10px;
    text-align: center;
  }
  .message {
      margin: 40px;
      opacity: 0;
      animation: fadeIn 1s ease-in 0.5s forwards;
    }
    
    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
  
    
    @media screen and (max-width: 480px) {
      .body{
        margin: 0 auto;
        height: 100%;
        width:100%;
      }
      .card{
          max-height: none;
          transition: all 3.3s cubic-bezier(.25,.8,.25,1);
      }
      
      body {     
          -webkit-text-size-adjust: 100%;     
          -ms-text-size-adjust: 100%;   } 
    }