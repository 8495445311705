/* variables.css */
:root {
    --primary-bacground-color:#274e65; /*   var(--primary-bacground-color);   */
    --primary-foreground-color: #FAFBFB; /*   var(--primary-foreground-color);   */
    --primary-foreground-50-color: #658091; /*   var(--primary-foreground-50-color);   */
    --primary-bright-bacground-color: #28325B; /*   var(--primary-bacground-color);   */
    --primary-bright-foreground-color: #a7b8c4; /*   var(--primary-bright-foreground-color);   */
    --primary-extra-bright-foreground-color: #f7f7f7; /*   var(--primary-extra-bright-foreground-color);   */
    --primary-selected-color: #652763; /*   var(--primary-selected-color);   */
    --primary-link-color: #58b0e4; /*   var(--primary-link-color);   */
    --primary-link-hover-color: #4890ba; /*   var(--primary-link-hover-color);   */
    --headline-large-font-size: 20px; /*   var(--headline-large-font-size);   */
    --headline-font-size: 18px; /*   var(--headline-font-size);   */
    --headline-icon-font-size: 18px;
    --price-bar-font-size: 17px;   /*   var(--price-bar-font-size);   */
    --label-font-size: 18px;/*   var(--label-font-size);   */
    --label-item-list-size: 16px;/*   var(--label-item-list-size);   */
    --item-List-Small-label-font-size: 14px; /*   var(--item-List-Small-label-font-size);   */
    --small-label-font-size: 12px; /*   var(--small-label-font-size);  */
    --price-bar-top-border-background: #274e6517;
    --price-bar-background:#f3f5f6;/*   var(--price-bar-background);  */
    --button-hover-background: #fafdff;/*   var( --button-hover-background);  */
}