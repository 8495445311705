.procceedBox{
  height: 100%;
  display: grid;
  align-self: center;
  align-items: center;
  grid-auto-rows:  1fr 1fr;
  -ms-overflow-style: none;
  grid-template-areas:
      "messageBox"
      "buttonBox";
}
.messageBox{
  color:var(--primary-bacground-color);
  padding: 20px;
  grid-area:messageBox;
  text-align: center;
  max-width: 400px;
}
.spinnerContainer{
    margin: 20px;
}
.buttonBox{
  padding: 0;
  grid-area:buttonBox;
  margin: 10px;
  text-align: center;
}
.message {
    margin: 40px;
    opacity: 0;
    animation: fadeIn 1s ease-in 0.5s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }