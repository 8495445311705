.linksWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10% 0;
    width: 100%;
}
.link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: disRegular;
    
    cursor: pointer;
    text-align: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    transform-origin: center;
}
.linkto{
    color: rgb(0, 113, 227);
    border-radius: 25px;
    border:1px solid rgb(0, 113, 227);
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
    transition:transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    backface-visibility: hidden;
}
.linkto:hover {
    transform: scale(1.1);
    background-color: rgb(3, 101, 199);
    color: white;
}

.linkto:active {
    transform: scale(0.98); /* Slightly shrink the button */
    background-color: rgb(2, 93, 184);
}
.arrow{
    text-align: center;
}
.link span span{
    align-items: center;
    text-align: center;
}
.brighter{
    border:1px solid rgb(165, 210, 255);
    color:rgb(165, 210, 255);
}
.brighter:hover {
    border:1px solid rgb(0, 113, 227);
    transform: scale(1.1);
    background-color: rgb(3, 101, 199);
    color: white;
}
.brighter:active {
    transform: scale(0.98); /* Slightly shrink the button */
    background-color: rgb(2, 93, 184);
}
@media screen and (max-width: 480px) {
    .link{
    font-size: 14px;
    }
  }




  

