.itemDetailsWrapper{
    margin: 5px;
    padding: 10px;
    font-size: var(--label-item-list-size); 
    background-color:var(--primary-foreground-color); 
}

.itemTable{
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing:  0 4px;
}