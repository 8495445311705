.detailHeader{
    height: 40px;
    margin: 2px;
}
.detailHeaderNum{
    text-align: center;
    border-bottom: 1px solid ;
}
.detailHeaderLocation{
    padding-left: 6px;
    border-bottom: 1px solid ;
}
.detailHeaderModel{
    padding-left: 6px;
    border-bottom: 1px solid ;
}