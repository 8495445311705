@import '../../../assets/fonts/fonts.module.css';
.wrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.container{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    
    
}
.socialContainer{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}
.box{
    font-family: disRegular;
    letter-spacing: .4px;
    color: #17262f;
    flex: 1;
    white-space: nowrap;
    padding: 20px;
}
.box ul{
    list-style-type: none;
}
.box a{
    text-decoration: none;
    color: #17262f;
}
.box span{
    display: inline-block;
    width: 20px;
    font-size: clamp(0rem, 3vw, .7rem);
}