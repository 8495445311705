.headerWrpper{
    display: flex;
    color: #17262f;;
}
.header{
    
    letter-spacing: .1px;
    font-family: disRegular;
    font-size: clamp(1rem, 3vw, 1.4rem); /* Set a max font size of 3rem and a min of 2rem */
    text-align: center;
    width: 100%;
}
.header h1{
    margin: 20px 20px 10px 20px;
    padding: 0;
}
.brightFont{
    color:#e4e4e6
}
@media screen and (max-width: 480px) {
    .header{
        letter-spacing: .1px;
        font-family: disRegular;
        font-size: 14px;
        width: 100%;
    }
   
  }