.productImageBox{
    grid-area: productImageBox;
    display: flex;
    align-items:flex-end;
    justify-content: flex-end;
}
.image{ 
    transition: all 3.3s cubic-bezier(.25,.8,.25,1);
    width: 310px;
    margin: 20px 20px 40px 0;
    transform: perspective(600px) rotateY(30deg);
    /* Ensure the transform origin is the middle */
    transform-origin: center;
}
.image:hover{ 
    transition: all 3.3s cubic-bezier(.25,.8,.25,1);
    width: 410px;
    margin: 20px 20px 40px 10px;
    transform: perspective(600px) rotateY(0deg);
    /* Ensure the transform origin is the middle */
    transform-origin: center;
}
@media screen and (max-width: 480px) {
    .productImageBox{
        align-items:center;
        justify-content: center;
        padding: 0;
    }
    .image{
        transition: all 5.3s cubic-bezier(.25,.8,.25,1);
        width:220px;
        margin: 16px 10px 6px 10px;
        transform: perspective(600px) rotateY(0deg) rotateX(0deg);
        /* Ensure the transform origin is the middle */
        transform-origin: center;
    }
    .image:hover{ 
        transition: all 5.3s cubic-bezier(.25,.8,.25,1);
        width:220px;
        margin: 16px 10px 6px 10px;
        transform: perspective(600px) rotateY(0deg) rotateX(0deg);
        /* Ensure the transform origin is the middle */
        transform-origin: center;
    }
    
  }