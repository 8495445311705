.box {
    display: flex;
    justify-content: flex-end;
}
.box button{
    background-color: var(--primary-bacground-color); /* Corrected variable */
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin: 4px 2px;
    cursor: pointer; 
    border: none;
    border-radius: 20px; /* This makes the sides round */
    transition: transform 0.3s ease, background-color 0.3s;
    outline: none;
    width: 150px;
    padding: 10px 20px;
    margin-top: 20px;
}
.box button:hover{
    transform: scale(1.1);
    background-color: var(--primary-bacground-color);
    color: white;
  }
  .box button:active {
    transform: scale(0.98); /* Slightly shrink the button */
    background-color: var(--primary-bacground-color);
}