.formWrapper{
    padding: 20px;
    font-family: disRegular;
    letter-spacing:.7px;
    color:  #17262f;
}
.formWrapper form{
    margin: 0;
    padding: 0;
}
.formWrapper h3{
    font-weight: 00;
    margin: 0;
    padding: 0;
}
.box{
    color:  #17262f;
    flex: 1;
    white-space: nowrap;
    padding: 20px;
}
.box ul{
    list-style-type: none;
}
.box a{
    text-decoration: none;
    color:  #17262f;
}
.box span{
    display: inline-block;
    width: 20px;
}
@media screen and (max-width: 480px) {
    
    .box{
        font-size: 16px;
    }
    .box span{
        font-size: 16px;
    }
  }