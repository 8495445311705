body {
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: rgb(252, 252, 252);
}

*{
  font-family:  disRegular;
  user-select: none;
}
