.cardWrapper{font-size: 16px;
    padding: 2%;
    display: flex;
    flex-direction: row;
    border-radius: 12.5px;
    flex: 1 1 auto;
    justify-content: center;
    max-width: 400px;
}
.borderedCard{
    border-radius: 12.5px;
    box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
    background-color: hwb(0 95% 5%);
    width: 100%;
}
.noBorderedCard{
    border-radius: 12.5px;
    color: #17262f;
    width: 100%;
    min-width: 320px;
    background-color:transparent;
}
.contentWrapper{
    background-color:transparent;
    border-radius: 12.5px;
    text-align: center;
    width: 100%;
}