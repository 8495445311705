
  @font-face {
    font-family: "disRegular";
    src: url("./font/disRegular.ttf")
  }
  @font-face {
    font-family: "securepolLogo";
    src: url("./font/securepolLogo.ttf")
  }

  .disRegular{
    font-family:  "disRegular",Helvetica,Arial,sans-serif;
  }
  .securepolLogo{
    font-family:  "securepolLogo",Helvetica,Arial,sans-serif;
  }
