.closeButton{
    width: 40px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    font-weight: bolder;
    cursor: pointer;
    background-color:none;
}
.closeButton:hover{
    
}