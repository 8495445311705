.sectionWrapper{
    
    font-family: disRegular;
    height: 100%;
    display: grid;
    grid-auto-rows: 62px 1fr;
    -ms-overflow-style: none;
    grid-template-areas:
        "header"
        "body";
}
.header{
    color:var(--primary-bacground-color);
    margin: 8px 10px;
    padding: 10px 10px 0 10px;
    grid-area:header;
    border-top-left-radius: .4em;
    border-top-right-radius: .4em;
    color:var(--primary-bacground-color);
}
.spacer{
    flex-grow: 1;
    margin: 5px;
}
.body {
    grid-area: body;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-self: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    color:var(--primary-bacground-color);
    
}
.body::-webkit-scrollbar {
    display: none;
}
.itemsWrapper{
    margin: 5px 20px;
    
    background-color: #ffffff;
    border-radius: 4px;
    
}
.imageGallery{
    margin:0 40px ;
}
.imageGalleryX{
    font-size: 10px;
    color:red;
}
.imageOverwrite{
    height: auto;
}
.infoBox{
    margin: 20px;
    margin-bottom: 40px;
}
.sectionBox{
    margin: 10px;
}
.infoTitle{ 
    font-family: disRegular;
    margin: 40px 0 20px 0;
    text-align: center;
    font-size: 30px;
    color:var(--primary-bacground-color);
}
.infoTable{font-weight: 600;
    border-collapse: collapse;
    width: 100%;
    color:var(--primary-bacground-color);
}
.infoTable tr:nth-child(odd) {
    background-color:  var(--primary-extra-bright-foreground-color);
    color:var(--primary-bacground-color);
}
.infoTable td{
    padding: 10px;
    color:var(--primary-bacground-color);
}
.td1{
    width: 100px;
    font-size: 16px;
    color:var(--primary-bacground-color);
}
.td2{
    font-weight: 500;
    font-size: 16px;
    color:var(--primary-bacground-color);
}
.td3{
    font-size: 16px;
    background-color: #fff;
    color:var(--primary-bacground-color);
}
.td4{
    font-size: 16px;
    background-color: #fff;
    color:var(--primary-bacground-color);
}

@media screen and (max-width: 480px) {
    .infoTitle{ 
        font-size: 28px;
    }
    .itemsWrapper{
        margin: 18px;
    }
    
    .modelCard{
        margin: 5px 20px;
    }
    .imageGallery{
        margin: 0  ;
    }

    .imageGalleryThumbnail {
    width: 40px;
}
.infoBox{
    margin: 0px;
    margin-bottom: 40px;
    font-size: 16px;
    color:var(--primary-bacground-color);
}
  }