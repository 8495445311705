
.socialLinks{
    display: flex;
    flex-direction:row;
    margin: 20px;
    justify-content: center;

}
.iconBox{
    margin: 10px;
}
.iconBox a{
    color:  #17262fa5;
}