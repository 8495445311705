

.itemSelect{
  padding: 1px;
    grid-area:select;
    height: 40px; /* Adjust height as needed */
    overflow-y: auto; /* Add scrollbars if options exceed height */
}

.selectVal{
    background-color: var(--primary-foreground-color); 
    border-radius: 6px;
    display: inline-block;
    font: inherit;
    line-height: .5em;
    padding: .2em;
    border: 2px solid var(--primary-bacground-color);
    width: 62px;
    max-height: 200px;
    text-align: center;
    color: var(--primary-bacground-color);
    font-size: 18px;
}
select:focus {
    outline: none;
  }
.checkBox{
    margin-top:5px;
    font-size: 28px;
    width: 42px;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
  } 
.radioBox{
  margin-top:5px;
    font-size: 28px;
    width: 42px;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}