

.style {
    font-size: 1.8rem;
    margin: 0px;
    padding: 10px 20px;
    cursor: pointer;
    color: #17262f;
    transition: transform 0.3s ease, color 0.3s ease;
}

.style:hover {
    transform: scale(1.2); /* Enlarges the icon on hover */
}