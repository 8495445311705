.productTitleBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 0 0;
}
.productTitle{
    font-family: disRegular;
    font-size: var(--label-font-size);
}
@media screen and (max-width: 480px) {
    .productTitleBox{
        margin: 14px 0 0 0;
        font-size: 10px
    }
  }