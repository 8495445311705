.proceedBox{
    height: 100%;
    width: 100%;
    display: grid;
    align-self: center;
    grid-auto-rows:  1fr 156px;
    grid-template-areas:
        "messageBox"
        "buttonBox";
  }
  .messageBox{
    color:var(--primary-bacground-color);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    grid-area:messageBox;
    max-width: 100%;
    padding: 25px;
  }
  .messageInstruction{
    margin:40px;
  }
  .itemBox{
    margin:  2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .itemWrapper{
    display: flex;
    flex-direction: row;
    width: 300px;
    
  }
  .icon{
    margin: 5px;
  }
  .item{
    margin: 5px;
  }
  .inputText{
    font-size: 16px;
    padding: 4px;
    border-radius: 6px;
    min-width: 250px;
  }
  
  .orderImage{
    max-width: 300px;
  }
  .buttonBox{
    background-color: var(--price-bar-background);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 0;
    
    grid-area:buttonBox;
    text-align: center;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.147); /* Top shadow */
    opacity: 0; /* Initial opacity */
    transform: translateY(100%); /* Initial position */
    animation: slide-up 1s forwards; /* Animation to slide up */
  }
  /* Keyframes for slide-up animation */
  @keyframes slide-up {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .table{
    display: flex;
      flex-direction: column;
      margin-left:26;
      margin-right:0;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
  }
  .tableRow{
      color:'#274E65';
      margin-top:1;
      display: flex;
      flex-direction: row;
      align-items: center,
  }
  .tableHeader{
    font-size: 12px;
    margin: 3px;
  }
  .tableCell{
  margin: 4px;
  font-size: 18px;
  flex-grow: 0;
  color:var(--primary-bacground-color);
  }
  .itemDetailsBox{
  display:flex;
  flex-direction:column;
  margin-left:40;
  margin-right:40;
  color:var(--primary-bacground-color);
  }
  
  .itemDetailsHeaderBox{
  display:flex;
  margin-left: 60px;
  flex-direction:row;
  background-color:#f7f8f9;
  font-size: 12px;
  padding: 1px;
  }
  .itemDetailsHeaderLocatoin{
  flex:1;
  }
  .itemDetailsHeaderModel{
  flex:1;
  }
  .pMarg{
  margin:1px;
  flex:1;
  }
  
  .itemDetailsRow{
  font-size: 14px;
  display:flex;
  flex-direction:row;
  margin-left:60px;
  }
  .itemDetailsOrderLabel{
  flex: 1;
  text-align: left;
  }
  .itemDetailsModelLabel{
  flex: 1;
  text-align: left;
  }
  
  
  
  .buttonActive {
    background-color: white; /* Green background */
    border: 2px solid var(--primary-bacground-color); /* Remove borders */
    color: var(--primary-bacground-color); /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the link block-level */
    font-size: 16px;
    font-weight: 600; /* Increase font size */
    margin: 4px 2px; /* Add some margin */
    cursor: pointer; /* Add a pointer cursor on hover */
    border-radius: 12px; /* Rounded corners */
    transition: transform 0.3s ease, background-color 0.3s; /* Smooth transition */
  }
  .buttonActive:hover{
    transform: scale(0.98);
    background-color: var( --button-hover-background);
  }
  .buttonInActive {
    background-color:rgb(230, 230, 230);/* Green background */
    border: 2px solid rgb(211, 210, 210); /* Remove borders */
    color: rgb(211, 210, 210); /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the link block-level */
    font-size: 16px;
    font-weight: bold; /* Increase font size */
    margin: 2px 2px; /* Add some margin */
    cursor: not-allowed; /* Add a pointer cursor on hover */
    border-radius: 12px; /* Rounded corners */
    transition: transform 0.3s ease, background-color 0.3s; /* Smooth transition */
    transform: scale(0.95);
  }
  
  .checkboxBox{
    margin: 16px 0;
    display: flex;
    justify-content: center;
  }
  .checkbox{
    color:var(--primary-bacground-color);
    cursor: pointer;
    margin: 5px;
    font-size: 22px;
  }
  .term{
    color: var(--primary-bacground-color);
    margin: 5px;
  }
  @media screen and (max-width: 480px) {
    .messageBox{
      padding: 25px;
    }
    .itemBox{
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    
  }
  
  