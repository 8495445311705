.cardWrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:4px;
    flex: 1;
    width: 100%;
}
.card{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;

}
.contentWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1% 4%;
}