.wrapper{
    margin: 10px;
    flex: 1;
}

p{
    margin: 0;
}
.ulList{
    color: #17262fcb;
    font-family: disRegular;
    font-weight:100;
    font-size: clamp(.6rem, 3vw, .7rem); /* Set a max font size of 3rem and a min of 2rem */
    width: 100%;
    margin: 10px;
    
}
.ulList h3{
    text-align: left;
}
.ulList ul{
    padding-left: 0;
    margin: 10px;
}
.ulList ul li a{
    color: #17262fcb;
    text-decoration: none;
}
.pointer{
    cursor: pointer;
}
.noBullets{
    list-style-type:none;
}
@media screen and (max-width: 480px) {
    .ulList{
        letter-spacing: .1px;
        font-family: disRegular;
        font-size: 10px;
        width: 100%;
    }
   
  }