@import '../../assets/fonts/fonts.module.css';


.contact{
    border-radius: 4px;
    background-color: #fefefe;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: stretch;
   justify-content: center;
   margin: 4%;
   color: #17262f;
}

.formWrapper{
    padding: 20px;
    font-family: disRegular;
    letter-spacing: -.3px;
    color: #17262f;
    
}
.wrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.container{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    
    
}
.socialContainer{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}
.box{
    font-family: disRegular;
    letter-spacing: -.3px;
    color: #17262f;
    flex: 1;
    white-space: nowrap;
    padding: 20px;
}
.box ul{
    list-style-type: none;
}
.box a{
    text-decoration: none;
    color: #17262f;
}
.box span{
    display: inline-block;
    width: 20px;
}
.footerWrapper{
    margin-top: 4%;
}