@import '../../../../calcAppGlobal.module.css';
.headerMenu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap:0 25px;
    margin: 0 10px;
    color:var(--primary-bright-foreground-color);
    
}
@media screen and (max-width: 480px) {
    .style{
    }
    
  }