.productFeaturesBox{
    font-family: disRegular;
    letter-spacing:.2px;
    grid-area: productFeatureBox;
    margin: 20px 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productFeatures{
    display: flex;
    flex-direction: column;
    width: 200px;
}
.featuresListBox{
    margin-bottom: 5px;
}
.featuresListTitle{
    border-bottom: 1px solid #1a3f501b;
    margin-bottom: 4px;
}
.featuresListTitle h3{
    font-family: disRegular;
    letter-spacing:.4px;
    font-weight: 600;
    font-size: 14px;
    margin: 3px;
}
.featuresListElements{
    display: flex;
    flex-direction: row;
}
@media screen and (max-width: 480px) { 
    .productFeaturesBox{
        margin: 0px;
    }
    .featuresListTitle h3{
        letter-spacing:.01px;
        font-size: 12px;
    }
  
  }