.priceList{
  display: flex;
  flex-direction:column;
  font-size: var(--price-bar-font-size);
  margin:5px;
  font-family: disRegular;
  letter-spacing:.2px;
  width: 100%;
}
.rowWrapper{
    display: flex;
    flex-direction: row;
}
.prevPrice{
  flex: 1 1 ;
  font-family: 'Roboto Mono', monospace;
  display: inline-block;
  text-align: right;
  margin-right: 0px;
  color:var(--primary-foreground-50-color);
  text-decoration: line-through;
  
}
.newPrice{
  flex: 1 1 ;
  font-family: 'Roboto Mono', monospace;
  text-align: right;
  
}
.label{
  flex: 4 1 ;
  padding-left: 5px;
}
 
@media screen and (max-width: 480px) {
  
    .priceList ul{
        list-style-type: none;
        padding:  0 14px;
    }
    .prevPrice{
      width: 94px;
  }
  .label{ 
    flex: 2 1 ;
  }
  }