

.appBackground{
  display: flex;
  position: fixed;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(208, 208, 208);
  z-index: 2;
}
.card{
  display: flex;
  width: 100%;
    max-width: 1128px;
    max-height: 100%;
  flex-direction: column;
  margin: 10px 15px 30px 15px;
  background-color: #ffffff;
  border-radius: 16px;
  transition: all 3.3s cubic-bezier(.25,.8,.25,1);
  justify-content: space-between;
  transition: transform 0.3s ease, background-color 0.3s, margin 0.3s;
}


.sectionWrapper {
  background-color: white; /* Initial background color */
  box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
  font-family: disRegular;
  border-radius: 16px;
  height: 100%;
  display: grid;
  grid-auto-rows: 1fr 62px;
  -ms-overflow-style: none;
  grid-template-areas:
      "body"
      "header";
  animation: change-background 3s forwards; /* Animation to change background color */
}

@keyframes change-background {
  to {
      background-color: var(--primary-bacground-color);/* Target background color */
  }
}


  .header{
    padding: 0;
    grid-area:header;
    margin: 10px;
    text-align: center;
  }
  .body {
    background-color: white;
    overflow-x: hidden;
    font-size: var(--label-font-size);
    display: flex;
    width:100%;
    height: 100%;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    border-radius: 16px;
    text-align: center;
    margin: 0px auto;
  }
  .messageBody{
    margin: 20px;
  }
  .loader{
    margin: 20px;
    padding: 20px;
  }
  .message{
    color:  var(--primary-bacground-color);
    font-size: 18px;
    padding: 20px;
  }
  @media screen and (max-width: 480px) {
    .body{
      margin: 0 auto;
      height: 100%;
      width:100%;
    }
  }
  @media screen and (max-width: 480px) {
    .card{
      margin: 3px;
        max-height: none;
        transition: all 3.3s cubic-bezier(.25,.8,.25,1);
    }
  }
  @media only screen and (max-device-width: 480px) {   
    body {     
        -webkit-text-size-adjust: 100%;     
        -ms-text-size-adjust: 100%;   } 
  }