
.elementBox{
    font-family: disRegular;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 60px;
}
.elementCheckBox{
    
    font-family: disRegular;
    font-size: var(--small-label-font-size);
    margin: 2px;
    border: 2px solid;
    border-radius: 2px;
    width: 12px;
    height: 12px;
}
.elementCheckBoxTitle{
    font-size:10px;
    margin: 2px;
    
}
@media screen and (max-width: 480px) { 
    .productFeaturesBox{
        margin: 0px;
    }
    .elementCheckBoxTitle{
        letter-spacing:.01px;
        font-size: 10px;
    }
  
  }