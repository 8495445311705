.optionBox{
    display: flex;
    font-family: disRegular;
    letter-spacing:.2px;
    margin: 0px 40px;
    padding-top: 0px;
    border-bottom: 1px solid #274e6512;
}
.optionBox h3{
    font-size: var(--headline-font-size);
    padding: 0px;
    margin: 0px 0 5px 0px;
}
.optionTitleBox{
    flex-grow: 1;
    
}
.optionEditButtonBox{
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
@media screen and (max-width: 480px) {
    .optionBox{
        font-size: 22px;
        margin: 0px 20px;
    }
    .optionBox h3{
        font-size: 20px
    }
    .productFeaturesBox{
        margin: 0px;
    }
}