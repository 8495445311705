@import '../../../../assets/fonts/fonts.module.css';
.header{
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    margin: 0;
  }
  .nav{
    font-size: 16px;
    background-color: rgb(255, 255, 255);
    font-family: disRegular;
    border-bottom: 1px solid rgba(0, 0, 0, 0.056);
    box-shadow: rgba(0, 0, 0, 0.263) 0px 1px 2px 0px;
  }
  
  .iconsWrapper{
    color: #17262f;
    height: 47px;
    width: 100%;
  }
  .burgerMenuIcon{
    float: left;
  }
  .callIcon{
    float: right;
  }
  .nav ul{
    list-style:none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .nav ul li{
    list-style: none;
    font-weight:bolder;
    margin: 0.81em 1.5em;
  
  }
  .link{
    font-size: 1.1rem;
    font-family: disRegular;
    font-weight: bold;
    letter-spacing:.7px;
    line-height: 1.2353641176;
    text-decoration: none;
    color: #1d1d1f;
  }
  .linksWrapper{
    padding: 18px;
    height: 100%;
    width: 100%;
    position:fixed;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgba(0, 0, 0, 0.36);
    
  }
  
  .link:hover{
    color: rgb(0, 0, 0);
  }
  .nav ul{
    flex-direction: column;
  }
  
  @media screen and (min-width:481px) {
    .nav{
      display: flex;
      justify-content: center;
    }
    .nav ul{
      flex-direction: row;
      }
      .nav div{
        justify-content: center;
      }
      .linksWrapper{
        padding: 0;
        height: 38;
        width: 0;
        position:relative;
        background-color: rgba(0, 0, 0, 0.813);
      }
  }
  