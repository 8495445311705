

.sectionWrapper{
    background-color:  var(--primary-bacground-color);
    box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
    font-family: disRegular;
    border-radius: 16px;
    padding: 0px;
    height: 100%;
    display: grid;
    grid-auto-rows:  1fr  92.5px 62px;
    -ms-overflow-style: none;
    grid-template-areas:
        "body"
        "footer"
        "header";
}
.titleBox{
 width: 100%;
 display: flex;
 flex-direction: row;
}
.title{
width: 100%;
}
.icon {
    width: 44.5px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 28px;
    transition: transform 0.4s ease; /* Set transition for the transform property */
    margin-top: 6px;
    padding: 2.5px;
}

.icon:hover {
    transform: scale(1.2); /* Use transform to scale the icon on hover */
}
.close{
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 38px;
    z-index: 1100;
    color:white;
}
.header{ 
    padding: 0;
    grid-area:header;
    margin: 10px;
    text-align: center;
}

.body{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    grid-area: body;
    background-color: var(--primary-foreground-color);
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-self: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    color:var(--primary-bacground-color);    
}
.body::-webkit-scrollbar {
    display: none;
}
.bodyElementsBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productElementsBox{
    display: grid;
    grid-auto-columns: 300px 1fr;
    -ms-overflow-style: none;
    grid-template-areas:
        "productImageBox productFeatureBox";
}
.optionBox{
    display: flex;
    font-size: 16px;
    color:var(--primary-bacground-color);
    margin: 10px 20px 0 20px;
    border-bottom: 1px solid #D4E4FD;
}
.optionTitleBox{
    flex-grow: 1;
}
.optionEditButtonBox{
    width: 20px;
    margin-right: 10px;
}


.footer{
    padding-top: 4px;
    border-top: 1px solid var(--price-bar-top-border-background);
    grid-area: footer;
    background-color: var(--price-bar-background);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 18px;
    color:var(--primary-bacground-color);
    z-index: 3;
}

.instructionsOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: scale(0);
    transform-origin: calc(100% - 20px) calc(0% + 20px); /* Adjusted to account for the 20px offset */
    transition: transform 0.4s ease, opacity 0.4s ease;
    pointer-events: none;
    z-index: 1000; /* Make sure it overlays the page */
    border-radius: 5px;
}

.instructionsOverlay.show {
    opacity: 1;
    transform: scale(1); /* Grow to full size */
    pointer-events: auto;
    
}

.instructionsModal {
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    position: relative;
    color: black;
    background: white;
    margin: 40px;
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 32px;
    cursor: pointer;
    color: var(--primary-background-color);
    padding: 0;
    line-height: 1;
    box-sizing: border-box;
  cursor: pointer;
    transition: transform 0.4s ease; /* Set transition for the transform property */
}

.closeBtn:hover {
    transform: scale(1.2); /* Use transform to scale the icon on hover */
}


.modalTitle {
    margin-top: 0;
  }
  .modalSubTitle{
    padding: 10px 0;
  }
  .modalBold{
    display: inline-block;
    width: 60px;
  }
  .modalList{
    list-style: none;
    padding-left: 0;
    margin: 0px;
  }
  .modalLine{
    padding: 0 0 0 10px;
  }



/* Add transition for the modal shrinking and growing effect */




@media screen and (max-width: 480px) {
    .card{
        max-height: none;
        transition: all 3.3s cubic-bezier(.25,.8,.25,1);
    }
    .sectionWrapper{
        grid-auto-rows:  1fr  94.5px 62px;  
    }
    .productElementsBox{
        font-size: 16px;
        display: grid;
        grid-auto-rows: 1fr 1fr;
        -ms-overflow-style: none;
        grid-template-areas:
            "productImageBox"
            "productFeatureBox";
    }
    
  }
  