
.itemBox{
    display: grid;
    grid-template-columns: 64px 1fr 42px;
    grid-template-areas:
        "select label option"
        "list list list";
    transition: max-height .3s linear;
    margin: 0px 20px;
    font-size: 16px;
}
.itemBox:hover{background-color:  #f5f7f89b;

}

.wrapper{
    margin: 0px;
}
.itemBox.active{
    transition: max-height .3s linear;
}

.itemLabel{
    margin-top:0px;
    grid-area:label;
    margin-left: 5px;
    display: flex;
    font-size: var(--label-font-size);
    align-items: center;
}
.itemOption{
    display: flex;
    grid-area:option;
    padding: 0.2em;
    text-align: center;
    justify-content: center;
    align-items: center; 
    color:var(--primary-bacground-color);
}
.itemOption:hover{
    background-color: #f5f7f89b;
    border-radius: 50px;
}
.slideDown {
    grid-area:list;
    overflow: hidden;
    transition: max-height .3s linear;
}
.slideDown.active {
    opacity: 0; /* Start fully transparent */
    animation: fadeInOut .3s forwards;
}
@keyframes fadeInOut {
    0% { opacity: 0; }
    100% { opacity: 1; } /* Fully visible after 1/3 of the animation time */
  }

