.container{
    border-bottom: 1px solid rgba(176, 176, 176, 0.368);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 20px;
}
.wrapper{
    text-align: center;
    display: inline-block;
    color: #17262fcb;
    font-family: disRegular;
    font-weight:100;
    margin: 20px;
}
.wrapper h3{
    color: #707375c5;
    font-family: securepolLogo;
    font-size: clamp(1.3rem, 3vw, 1.6rem);
    margin: 2px;
}
.wrapper p{
    font-size: clamp(0.4rem, 3vw, .7rem);
    margin: 2px;
}

.copyright{
    text-align: center;
    margin: 5px 20px 60px 20px;
    font-family: disRegular;
    font-size: clamp(0.1rem, 3vw, 0.6rem);
    color: #17262fcb;
}