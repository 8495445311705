.cardWrapper{
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    border-bottom:1px solid rgba(176, 176, 176, 0.368);
    padding-bottom: 30px;
}

.container{
    margin: 20px;
}