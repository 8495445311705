/* Modal overlay container */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 1000; /* Ensure the modal is above the page */
    transition: opacity 0.4s ease; /* Only fading transition */
}

/* Show the modal in the center */
.modalOverlay.show {
    opacity: 1; /* Ensure visibility */
    pointer-events: auto; /* Enable interaction */
}

/* Modal box content */
.modalBox {
    margin: 20px;
    padding: 0px;
    border-radius: 18px;
    position: relative;
    color: black;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    transition: opacity 0.4s ease; /* Fade in/out for modal box */
    transition: transform 0.4s ease; /* Adjust this to match the JS timeout */
}

.imageLoader {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #999;
}
/* Image box */
.imageBox {
    flex: 1 1;
    text-align: center;
    padding: 10px 20px 0 20px;
}

.imageBox img {
    border-radius: 18px;
    width: 100%;
}

/* Text and Button styles */
.textBox {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    color: var(--primary-bacground-color); /* Ensure correct variable */
}

.headerBox {
    text-align: center;
    margin: 10px;
}

.headerBox h2 {
    padding: 0;
    margin: 0;
}

.contentBox {
    text-align: center;
    margin: 0px 10px;
    font-size: 16px;
    max-width: 280px;
    height: 68px;
}

.buttonBox {
    flex: 1 1;
    text-align: center;
    margin: 20px 10px;
    padding: 14px;
}

.button {
    background-color: var(--primary-bacground-color); /* Corrected variable */
    border: 1px solid var(--primary-bacground-color);
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin: 4px 2px;
    cursor: pointer; 
    border: none;
    border-radius: 20px; /* This makes the sides round */
    transition: transform 0.3s ease, background-color 0.3s;
    outline: none;
    width: 150px;
    padding: 10px 20px;
}

.button:hover {
    transform: scale(1.1);
    background-color: var(--primary-bacground-color);
    color: white;
}

.button:active {
    transform: scale(0.98); /* Slightly shrink the button */
    background-color: var(--primary-bacground-color);
}
/* InfoModal.module.css */

.modalOverlay {
    /* Your existing overlay styles */
}

.modalBox {
    /* Your modal box styles */
    transition: transform 0.4s ease; /* Adjust this to match the JS timeout */
}

.imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New styles for fading in images */
.fadeIn {
    opacity: 1;
    transition: opacity 0.8s ease-in; /* Smooth fade-in effect */
}

.hidden {
    opacity: 0; /* Initially hidden */
}

.imagePlaceholder {
    width: 400px; /* Set fixed width */
    height: 320px; /* Set fixed height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageLoader {
    font-size: 16px;
}
/* InfoModal.module.css */

/* Container for dots */
.dotsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

/* Each dot */
.dot {
    height: 4px;
    width: 4px;
    margin: 3px;
    border-radius: 25px;
    background-color: #ffffff;
    border: 1px solid var(--primary-bacground-color);
    display: inline-block;
    transition: background-color 0.3s;
}

/* Active dot */
.activeDot {
    background-color: var(--primary-bacground-color);
}



.imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fadeIn {
    opacity: 1;
    transition: opacity 0.8s ease-in;
}

.hidden {
    opacity: 0;
}



.imageLoader {
    font-size: 16px;
    color: #999;
}
@media screen and (max-width: 480px) {
    .imagePlaceholder {
        width: 340px; /* Set fixed width */
        height: 320px; /* Set fixed height */
       
    }
}