.termsWrapper{
    display: flex;
    text-align: justify;
}

p{
    margin: 0;
}
.terms{
    color: #17262fcb;
    font-family: disRegular;
    font-weight:100;
    font-size: clamp(0.4rem, 3vw, 0.7rem); /* Set a max font size of 3rem and a min of 2rem */
    width: 100%;
}