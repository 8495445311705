.sectionWrapper{
    background-color: var(--primary-foreground-color);
    box-shadow: rgba(54, 54, 54, 0.858) 1px 1px 2px 0px;
    font-family: disRegular;
    border-radius: 16px;
    height: 100%;
    display: grid;
    grid-auto-rows: 62px 1fr  60.5px ;
    -ms-overflow-style: none;
    grid-template-areas:
        "header"    
        "body"
        "footer"
        ;
}
.header{
    color:var(--primary-bacground-color);
    margin: 8px 10px;
    padding: 10px 10px 0 10px;
    grid-area:header;
    border-top-left-radius: .4em;
    border-top-right-radius: .4em;
}
.category{
    font-size: 16px;
}
.category h2{
    padding: 10px;
    margin: 10px;
    font-size: 16px;
}
.image{
    height: 120px;
    cursor: pointer;
}
.menu{
    width: 40px;
    margin: 5px;
}
.spacer{
    flex-grow: 1;
    margin: 5px;
}
.body {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    grid-area: body;
    background-color: var(--primary-foreground-color);
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-self: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    color: var(--primary-bacground-color);
}
.body::-webkit-scrollbar {
    display: none;
}
.itemsWrapper{
    margin: 5px;
}

.modelCardActive{
    background-color: #ffffff;
    box-shadow: rgba(54, 54, 54, 0.858) 1px 1px 1px 0px;
    border: 1px solid rgba(54, 54, 54, 0.267);
    border-radius: 16px;
    margin: 0px 40px;
    padding: 10px;
    color: var(--primary-bacground-color); 
    transition: transform 0.3s ease, background-color 0.3s,box-shadow 0.3s; ; 
    transform: scale(0.99);
}
.modelCardInActive{
    background-color: #ffffff;
    border: 1px solid rgba(54, 54, 54, 0.267);
    box-shadow: rgba(54, 54, 54, 0.858) 2px 2px 2px 0px;
    border-radius: 16px;
    margin: 0px 40px;
    padding: 10px;
    color: var(--primary-bright-foreground-color);
    transition: transform 0.3s ease, background-color 0.3s,box-shadow 0.3s; ; 
}
.modelCardInActive:hover{
    box-shadow: rgba(54, 54, 54, 0.858) 1px 1px 1px 0px;
    border: 1px solid rgba(54, 54, 54, 0.267);
    color:#274e6579;
    transition: transform 0.3s ease, background-color 0.3s, box-shadow 0.3s; 
    transform: scale(0.99);
}

.modelElements{
    display: grid;
    min-height: 200px;
    height: 100%;
    grid-auto-columns: 1fr 1fr 33.5px;
    -ms-overflow-style: none;
    grid-template-areas:
        "image text select";
}

.image{
    grid-area: image;
    height: 100%;
    color:#ADCAEE;
}
.imageWrapper{
    margin: 0px 14px 0 0;
    display: grid;
    grid-auto-rows: 1fr 33.5px;
    -ms-overflow-style: none;
    height: 100%;
    gap: 0px;
    grid-template-areas:
        "imageBox"
        "imageDots";
}
.imageBox{
    width: 90%;
    grid-area: imageBox;
    display: flex;
    justify-content: center;
    
}
.imageBox img{
    width: 100%;
    height: auto;
    justify-content: center;
    align-self: center;
}
.imageDots{
    color: var(--primary-bacground-color);
    grid-area: imageDots;
    font-size: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-self: center;
}
.text{
    grid-area: text;
    color: var(--primary-bacground-color);
    display: grid;
    grid-auto-rows: 1fr 2fr 23.5px;
    -ms-overflow-style: none;
    font-size: 16px;
    grid-template-areas:
        "title"
        "description"
        "more";
}
.title{
    padding: 2px;
    grid-area: title;
}
.title h3{
    margin: 10px 0 0 0;
    padding: 0;
}
.description{
    padding: 2px;
    margin: 0px 30px 0 0;
    grid-area: description;
}
.more{
    z-index: 2;
    padding: 0px;
    grid-area: more;
    color:var(--primary-link-color);
    text-align: right;
    cursor: pointer;
    margin: 0px 0 10px 0;
}
.more:hover{
    color:var(--primary-link-hover-color); 
}

.selectionDot{
    grid-area: select;
    font-size: 33px;
    display: flex;
    justify-content: center;
    align-self: center;
    
}

.footer{
    padding-top: 4px;
    border-top: 1px solid var(--price-bar-top-border-background);
    grid-area: footer;
    background-color: var(--price-bar-background);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 18px;
    color:var(--primary-bacground-color);
    z-index: 3;
}

@media screen and (max-width: 480px) {
    .text{
        font-size: 16px;
    }
    .itemsWrapper{
        margin: 4px;
    }
    .footer{
       font-size: 16px;
    }
    .modelCard{
        margin: 0px 10px;
    }
    .modelCardActive{
        margin: 0px 10px;
    }
    .modelCardInActive{
        margin: 0px 10px;
    }
    .description{
        padding: 2px;
        margin: 0px 10px 10px 0;
        grid-area: description;
    }
  }