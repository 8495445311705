.ds{
    background-color:var(--primary-foreground-color); 
    border-top: 2px solid white;
}
.detailRow{
    display: flex;
    height: 30px;
    margin: 2px;
}
.itemNum{
    height: 30px;
    width: 24px;
    text-align: center;
    border-bottom:1px solid #1a3f5025;
}
.itemLocation{
    border-bottom:1px solid #1a3f5025;
}
.locationInput{
    border: none; /* Removes the border */
    outline: none; /* Removes the outline when the input is focused */
   width: 96%;
   padding-left: 6px;
   background-color: none;
    font-size: var(--label-item-list-size); 
    color:var(--primary-bacground-color); 
    background-color:#ffffff;
    padding: 4px;
    border-radius: 0;
}
.itemLocation input::placeholder {
    font-size: var(--label-item-list-size); 
}
.itemCategory{
    border-bottom:1px solid #1a3f5025;
    padding-right: 6px;
    width: 100px;
    font-size: var(--label-item-list-size); 
}
.modelWrapper{
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}
.itemModel{
    border-bottom:1px solid #1a3f5025;
    padding-left: 6px;
    cursor: pointer;
    width: 260px;
    font-size: var(--label-item-list-size); 
}
.itemOpen{
    text-align: right;
    border-bottom:1px solid #1a3f5025;
    cursor: pointer;
    font-size: var(--label-item-list-size); 
}
@media screen and (max-width: 480px) {
    .itemModel{
        width: 170px;
        font-size: var(--label-item-list-size); 
    }
    .itemCategory{
        width: 100px;
        font-size: var(--label-item-list-size); 
    }
  }