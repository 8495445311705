@import '../calcAppGlobal.module.css';

.sectionWrapper{
  background-color:  var(--primary-bacground-color);
  box-shadow: rgba(54, 54, 54, 0.858) 0px 1px 2px 0px;
  font-family: disRegular;
  border-radius: 16px;
  height: 100%;
  display: grid;
  grid-auto-rows:  1fr 62px;
  -ms-overflow-style: none;
  grid-template-areas:
      "body"
      "header";
      
}
.header{
  padding: 0;
  grid-area:header;
  margin: 10px;
  text-align: center;
}
.body {
  background-color: white;
  overflow-x: hidden;
  font-size: var(--label-font-size);
  display: flex;
  width:100%;
  height: 100%;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  border-radius: 16px;
  text-align: center;
  margin: 0px auto;
}
.body h3{
  color: var(--primary-bacground-color);
  margin: 26px;
  padding:0;
}
.body::-webkit-scrollbar {
  display: none;
}
.formBox{
  width: 420px;
}
.form{
  display: flex ;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 20px;
}
.input{
  flex: 1 1;
  font-size: var(--label-font-size);
 
}
.textarea{
  flex: 1 1;
  font-size: var(--label-font-size);
}
.buttonBox{
  font-size: var(--label-font-size);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.locationCheckBox{
  color: var(--primary-bacground-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.hidde{
  display: none;
}
.show{
 display: block;
}
.locationCheckBox{
  margin-top: 16px;
  margin-bottom: -4px;
  font-size: 16px;
}
.sentAlertIcon{
  color:var(--primary-bacground-color);
  font-size: 45px;
}
.checkIcon{
  margin:3px 6px 0 0;
  font-size: 20px;
}
.alertBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.sentAlert{
  color:var(--primary-bacground-color);
}
.sentText{
  text-align: center;
  margin: 60px;
  max-width: 600px;
}
.serviceAddressTitle{
  color:var(--primary-bacground-color);
  margin-top: 20px;
}
.checkLabel{
  margin-top: 4px;
  color:var(--primary-bacground-color);
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  .body{
    margin: 0 auto;
    height: 100%;
    width:100%;
  }
  .body h3{
    margin: 16px 0 -2px 0;
    padding:0;
  }
  .sectionWrapper{
      grid-auto-rows:  1fr  62px;   
  }
  .formBox{
    padding: 0px;
  }
  .form{
    margin: 0 auto;
    width: 90%;
  }
  .locationCheckBox{
    margin-top: 16px;
    margin-bottom: -4px;
    font-size: 16px;
  }
  .sentText{
    text-align: left;
    margin: 40px;
  }
}