.active{
    color: var(--primary-foreground-color); 
}
.inActive{
    color:inherit;
}
.box{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    
}
.iconWrapper{

   }
.icon{
 font-size: 26px;
}
.label{
    margin-top: -4px;
    font-size: 14px;
   
}