.box {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.box label{
    color: var(--primary-bacground-color);
    font-size: 16px;
    display: block;
    margin-bottom: 0.2rem;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin: 5px 0;
}
.box input{
    min-width: 300px;
     padding: 0.5rem;
    font-size: 16px;
    border: 1px solid var(--primary-bacground-color);
    border-radius: 4px;
}
.box input:focus{
    outline: none;
}
.box input::placeholder{ 
    font-size: 14px;
    color: rgb(199, 199, 199)
}
@media screen and (min-width:481px) {
    .box{
        font-size: 16px;
    }
  }