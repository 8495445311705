
.appBackground{
    display: flex;
    position: fixed;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(208, 208, 208);
    z-index: 2;
}
.card{
    display: flex;
    width: 100%;
    max-width: 820px;
    max-height: 940px;
    flex-direction: column;
    margin: 3px;
    background-color: #ffffff;
    border-radius: 16px;
    transition: all 3.3s cubic-bezier(.25,.8,.25,1);
    justify-content: space-between;
}

@media screen and (max-width: 480px) {
    .card{
        max-height: none;
        transition: all 3.3s cubic-bezier(.25,.8,.25,1);
    }
}
@media only screen and (max-device-width: 480px) {   
    body {     
        -webkit-text-size-adjust: 100%;     
        -ms-text-size-adjust: 100%;   } 
}