.procceedBox{
    height: 100%;
    display: grid;
    align-self: center;
    align-items: center;
    grid-auto-rows:  1fr 1fr;
    -ms-overflow-style: none;
    grid-template-areas:
        "messageBox"
        "buttonBox";
  }
  .messageBox{
    color:var(--primary-bacground-color);
    grid-area:messageBox;
    text-align: center;
    max-width: 400px;
    padding: 25px;
  }
  .orderImage{
    max-width: 300px;
  }
  .buttonBox{
    padding: 0;
    grid-area:buttonBox;
    margin: 10px;
    text-align: center;
  }
  .button {
    background-color: white; /* Green background */
    border: 2px solid var(--primary-bacground-color); /* Remove borders */
    color: var(--primary-bacground-color); /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the link block-level */
    font-size: 16px;
    font-weight: 600; /* Increase font size */
    margin: 4px 2px; /* Add some margin */
    cursor: pointer; /* Add a pointer cursor on hover */
    border-radius: 12px; /* Rounded corners */
    transition: transform 0.3s ease,background-color 0.3s; /* Smooth transition */
  }
  .button:hover{
    transform: scale(0.97);
    background-color: var( --button-hover-background);
  }
  